.content {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
  padding: var(--gutter);

  @media screen and (max-width: 768px) {
    margin-left: 0;
    margin-right: 0;
    padding: 3rem 1rem;
  }
}

.px-gutter {
  padding-left: var(--gutter);
  padding-right: var(--gutter);
}

.pl-gutter {
  padding-left: var(--gutter);
}

.pr-gutter {
  padding-right: var(--gutter);
}

.mx-gutter {
  margin-left: var(--gutter);
  margin-right: var(--gutter);
}

.ml-gutter {
  margin-left: var(--gutter);
}

.mr-gutter {
  margin-right: var(--gutter);
}

.right-gutter {
  right: var(--gutter);
}

.left-gutter {
  left: var(--gutter);
}

.stack {
  display: flex;
  flex-direction: column;
}

.remark {
  display: none;
}
