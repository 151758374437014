@font-face {
  font-family: "FuturaRound";
  src: url("/fonts/FuturaRound-Bold.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-regular-italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/test-geograph-light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-medium.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-black.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Geograph";
  src: url("/fonts/geograph-black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Druk Super";
  src: url("/fonts/Druk-Super.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Druk Condensed";
  src: url("/fonts/DrukCond-Super-Web.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
