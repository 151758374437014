@import "tokens/color";
@import "tokens/typography";
:root {
  --container-width: 1283px;
  --container-max-width: 100%;
  --container-margin-left: auto;
  --container-margin-right: auto;

  --gutter: 2.5rem;

  --border-radius: 2rem;
  --button-border-radius: 2.8rem;

  @media screen and (max-width: 768px) {
    --gutter: 1rem;
  }
}
