:root {
  --dark-blue: #0c2340;
  --teal: #629ca7;
  --light-teal: #90d1cb;
  --light-teal-bg: hsla(171, 35%, 72%, 0.6);
  --lighter-teal: #6f9ba4;
  --blue: #2570c7;
  --cream: #fefdeb;
  --orange: #ffae39;
  --green: #205a41;
  --light-green: #cde0d7;
  --campus-orange: #d06338;
  --campus-purple: #572a4f;
  --campus-red: #971b2f;
  --camput-light-red: #f3d1d0;
  --campus-teal: #6f9ba4;

  --background: #f9f9f9;

  --nav-background: var(--dark-blue);
}

.bg-dark-blue,
.bg-green,
.bg-lighter-teal,
.bg-purple,
.bg-campus-purple {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a {
    color: #fff;
  }

  --border-color: white;

  input.bg-transparent,
  textarea.bg-transparent {
    color: #f1f1f1;
  }

  input.bg-transparent::placeholder,
  textarea.bg-transparent::placeholder {
    color: #f1f1f1;
  }

  input.bg-white::placeholder,
  textarea.bg-white::placeholder,
  .select.bg-white::placeholder {
    color: var(--dark-blue);
    opacity: 0.5;
  }

  input.bg-white,
  textarea.bg-white,
  .select.bg-white {
    color: var(--dark-blue);
  }
}

.bg-blue {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  a {
    color: #fff;
  }

  --border-color: white;
}

.bg-orange {
  .divider {
    background-color: var(--dark-blue);
  }
}

.border-color {
  border-color: var(--border-color);
}

section.bg-transparent,
div.bg-transparent {
  --border-color: var(--dark-blue);
}

// Themes

body {
  .nav-effect {
    mix-blend-mode: multiply;
  }

  .tag {
    background-color: var(--orange);
    color: var(--dark-blue);
  }
}

body.theme-green {
  --nav-background: var(--green);
  .nav-effect {
    mix-blend-mode: multiply;
  }

  .card-grid-overlay {
    background-color: hsla(152, 48%, 30%, 0.85);
    svg {
      path {
        fill: #80b09a;
      }
    }
  }

  .tag {
    background-color: #4d7963;
    color: #fff;
  }
}
